import {useState, useEffect} from 'react'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import {PageTitle} from '../../_metronic/layout/core'
import {obterDadosAcidente} from '../../app/utils/acidenteUtils'
import {DadosNaoEncontrados} from '../DadosNaoEncontrados'

interface Condutor {
  nome: string
  cpf: string
  idade: number
  sexo: string
  veiculo_id: string
}

interface Veiculo {
  id: string // Adicione esta linha
  cor: string
  marca: string
  modelo: string
  placa: string
  tipo: string
  veiculo_retirado: number
}

export default function Condutores() {
  const [condutores, setCondutores] = useState<Condutor[]>([])
  const [veiculos, setVeiculos] = useState<Veiculo[]>([])

  async function fetchDadosAcidente() {
    try {
      const {condutores, veiculos} = await obterDadosAcidente()
      setCondutores(condutores)
      setVeiculos(veiculos)
    } catch (error) {
      console.log('Erro ao buscar dados do acidente: ', error)
    }
  }

  useEffect(() => {
    fetchDadosAcidente()
  }, [])

  return (
    <div>
      <PageTitle breadcrumbs={[]}>Detalhamento</PageTitle>
      <strong style={{color: '#a1a5b7'}}>CONDUTORES</strong>
      <br />
      <br />
      {condutores.length === 0 ? (
        <DadosNaoEncontrados mensagem='Oops... Nenhum condutor cadastrado' imagemWidth='30%'/>
      ) : (
        <div className='row row-cols-1 row-cols-lg-2 g-9'>
          {condutores.map((condutor, index) => {
            const veiculoCorrespondente = veiculos.find(
              (veiculo) => veiculo.id === condutor.veiculo_id
            )
            return (
              <div
                key={index}
                className={`card card-bordered ${condutores.length === 1 ? 'mx-auto' : ''}`}
              >
                <div className='card-header justify-content-end ribbon ribbon-start ribbon-clip'>
                  <div className='card-title'>
                    Condutor do Veículo: {veiculoCorrespondente?.placa}.
                  </div>
                </div>
                <div className='card-body'>
                  <Form>
                    <Row>
                      <Col>
                        <Form.Label>NOME</Form.Label>
                        <Form.Control
                          type='text'
                          value={condutor.nome.toUpperCase() || 'NÃO INFORMADO'}
                          disabled
                          className='text-dark'
                          style={{backgroundColor: '#e9ecef'}}
                        />
                      </Col>
                      <Col>
                        <Form.Label>CPF</Form.Label>
                        <Form.Control
                          type='text'
                          value={condutor.cpf || 'NÃO INFORMADO'}
                          disabled
                          className='text-dark'
                          style={{backgroundColor: '#e9ecef'}}
                        />
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <Form.Label>IDADE</Form.Label>
                        <Form.Control
                          type='text'
                          value={condutor.idade || 'NÃO INFORMADO'}
                          disabled
                          className='text-dark'
                          style={{backgroundColor: '#e9ecef'}}
                        />
                      </Col>
                      <Col>
                        <Form.Label>SEXO</Form.Label>
                        <Form.Control
                          type='text'
                          value={condutor.sexo || 'NÃO INFORMADO'}
                          disabled
                          className='text-dark'
                          style={{backgroundColor: '#e9ecef'}}
                        />
                      </Col>
                    </Row>
                    <br />
                  </Form>
                </div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
