import HttpService from './HttpService'
import Cookies from 'universal-cookie'
import jwt_decode from 'jwt-decode'

const cookies = new Cookies()

export default class UsuarioService extends HttpService {
  //AUTH
  async login(credenciais) {
    const response = await this.post('/auth/login', credenciais)
    let data, status

    if (response) {
      data = response.data
      status = response.status
    }

    try {
      if (status === 200) {
        const token = data.access_token
        const decodedToken = jwt_decode(token)

        // Extrai o tipo de acesso do token decodificado
        const tipoAcesso = decodedToken.acesso

        // Armazena o tipo de acesso nos cookies
        cookies.set('tipo_acesso', tipoAcesso, { maxAge: 10 * 60 * 60 })

        // Armazena outros dados necessários nos cookies, se necessário
        cookies.set('nome', data.nome, { maxAge: 10 * 60 * 60 })
        cookies.set('matricula', data.matricula, { maxAge: 10 * 60 * 60 })
        cookies.set('id', data.id, { maxAge: 10 * 60 * 60 })
        cookies.set('token', token, { maxAge: 10 * 60 * 60 })
      }
    } catch (error) {
      console.error(error)
      console.error('Falha no login')
    }

    return { data, status }
  }
  estaAutenticado() {
    //return true;
    return cookies.get('token') !== undefined
  }
  async esqueciSenha(cpf) {
    try {
      const {data, status } = await this.post(`/auth/forgot/${cpf}`)
      return { data, status }
    } catch (error) {
      console.error('Erro ao ativar usuário:', error)
      throw error; 
    }
  }
  async resetandoSenha(dados) {
    try {
      const {data, status } = await this.post(`/auth/reset`, dados)
      return { data, status }
    } catch (error) {
      console.error('Erro ao ativar usuário:', error)
      throw error; 
    }
  }

  //USER

  async cadastro(dados) {
    const { data, status } = await this.post('/agente', dados)
    return { data, status }
  }
  async cadastroAdmin(dados) {
    const { data, status } = await this.post('/agente/admin', dados)
    return { data, status }
  }
  async usuario(matricula) {
    const response = await this.get(`/agente/matricula/${matricula}`)
    try {
      return response
    } catch (error) {
      console.error('Erro ao obter os dados do usuario:', error)
    }
  }
  async atualizarUsuario(id, dados) {
    const { data, status } = await this.put(`/agente/${id}`, dados)
    return { data, status }
  }
  async avatar(id) {
    try {
      const response = await this.get(`/agente/${id}/foto`)
      return response
    } catch (error) {
      console.error('Erro ao obter os dados das fotos:', error)
      return null
    }
  }
  async enviarAvatar(id) {
    try {
      const response = await this.post(`/${id}/foto`)
      return response
    } catch (error) {
      console.error('Erro ao enviar foto:', error)
      return null
    }
  }
  async allUsuarios() {
    try {
      const response = await this.get(`/agente`)
      return response
    } catch (error) {
      console.error('Erro ao obter todos os agentes:', error)
      return null
    }
  }
  async desativarUsuario(id) {
    try {
      const {data, status } = await this.delete(`/agente/${id}`)
      return {data, status }
    } catch (error) {
      console.error('Erro ao desativar usuário:', error)
      return null
    }
  }
  async ativarUsuario(id) {
    try {
      const {data, status } = await this.put(`/agente/reactivate/${id}`)
      return {data, status }
    } catch (error) {
      console.error('Erro ao ativar usuário:', error)
      return null
    }
  }

  //ACIDENTE
  async imagens(id) {
    try {
      const response = await this.get(`/foto/${id}`)
      return response
    } catch (error) {
      console.error('Erro ao obter os dados das fotos:', error)
      return null
    }
  }
  async acidenteID(id) {
    const response = await this.get(`/acidente/${id}`)
    try {
      return response
    } catch (error) {
      console.error('Erro ao obter os dados do acidenteID:', error)
      throw error
    }
  }
  async allRatsCadastradosUsuario(id) {
    try {
      const response = await this.get(`/agente/${id}/acidentes`)
      return response
    } catch (error) {
      console.error('Erro ao obter os dados das fotos:', error)
      return null
    }
  }

  //CAUSA
  async allCausas() {
    const response = await this.get(`/causa`)
    try {
      return response
    } catch (error) {
      console.error('Erro ao obter os dados do acidenteID:', error)
      throw error
    }
  }

  //ORGAOS
  async todosOsOrgaos() {
    const response = await this.get(`/orgao`)
    try {
      return response
    } catch (error) {
      console.log(error)
    }
  }

  // FILTRO
  async fitro(dados) {
    const response = await this.post('/acidente/filter', dados)
    try {
      return response.data || []
    } catch (error) {
      console.error('Erro ao obter os dados do acidente:', error)
      throw error
    }
  }
}
