import React from 'react';
import TerceiroBI from '../../../componentes/BI/TerceiroBi';

const TerceiroBiPage = () => {
  
  return (
    <div className="card mb-10">
      <TerceiroBI />
    </div>
  );
};

export default TerceiroBiPage;
