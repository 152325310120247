import React from 'react';
import { PageTitle } from '../../../_metronic/layout/core';

const SegundoBI = () => {
  return (
    <div>
        <PageTitle breadcrumbs={[]}>B.I.</PageTitle>
      <div className='mb-10 text-center'>
          <h1 className='text-dark mb-3'>Segundo B.I.</h1>
        </div>
      <iframe
        src="https://app.powerbi.com/view?r=eyJrIjoiMjE2NWZhZTctMWVkZC00ODJjLWI2ZGUtZWRjMWZkNjdhMTk3IiwidCI6ImExZjAxOGVmLWZmZjktNDIyNS1hMWEzLTczMjBkNGUwNmMwYSJ9"
        title="BI Dashboard"
        width="100%"
        height="600px"
        frameBorder="0"
      ></iframe>
    </div>
  );
};

export default SegundoBI;
