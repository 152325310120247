import React from 'react';

interface InputFormularioProps {
  label?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  obrigatorio?: boolean;
  descricao?: string;
  placeholder?: string;
  maxLength?: number;
  mensagemErro?: string | false;
  disabled?: boolean;
  style?: React.CSSProperties;
  cruzamentoInputStyle?: boolean;
  className?: string;
}

const InputFormulario: React.FC<InputFormularioProps> = ({
  label,
  value,
  onChange,
  type = 'text',
  obrigatorio = false,
  descricao,
  placeholder,
  maxLength,
  mensagemErro,
  disabled,
  style,
  cruzamentoInputStyle,
  className,
}) => {
  const hasError = obrigatorio && !value;

  return (
    <div className={`row ${cruzamentoInputStyle ? 'cruzamento-input' : ''}`}>
      <label className='col-lg-12 col-form-label '>{label}</label>
      <div className='col-lg-9 '>
        <input
          className={`form-control form-control-solid ${className} ${hasError ? 'is-invalid' : ''}`}
          value={value}
          onChange={onChange}
          type={type}
          required={obrigatorio}
          placeholder={placeholder}
          maxLength={maxLength}
          disabled={disabled}
          style={style}
        />
        {hasError && mensagemErro && <div className='invalid-feedback'>{mensagemErro}</div>}
        {obrigatorio && <div className='form-text text-muted'>{descricao}</div>}
      </div>
    </div>
  );
};

export default InputFormulario;
