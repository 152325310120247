import React from 'react'
import {Button} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faDownload} from '@fortawesome/free-solid-svg-icons'
import {AcidenteExport} from './types'

interface Props {
  dadosExport: AcidenteExport[]
}

export function ExportarDados({dadosExport}: Props) {
  console.log('daaaa', dadosExport)
  const exportarParaCSV = () => {
    const data = new Date().toISOString().slice(0, 10)
    const filename = `dados_${data}.csv`

    // Cabeçalhos das colunas
    const headers = [
      'Rua 1', 'Rua 2', 'Bairro', 'Cidade', 'UF', 'Latitude', 'Longitude',
      'Referência', 'Data Ocorrência', 'Hora', 'Tipo de Acidente', 'Pavimento',
       'Descrição do Sinistro', 'Sugestões de Melhorias',
      'Falta de Atenção', 'Órgão Solicitador', 'Primeiro Atendimento',
      'Causas', 'Infrações', 'Órgãos Presentes', 'Sinalizações', 'Veículos Retirados', 'Tipo de Veículo', 'Marca de Veículo', 'Placa de Veículo', 'Cor de Veículo', 
      'Modelo de Veículo', 'Tipo Transporte do Veículo','Condutor Nome', 'Condutor CPF', 'Condutor Idade', 'Condutor Sexo', 'Veículo do Condutor', 'Vítima Nome', 'Vítima CPF', 'Vítima Idade', 'Vítima Sexo', 'Atendimento Vítima', 'Condição Vítima', 'Veículo da Vítima'
    ]

    // Dados das linhas
    const rows = dadosExport.map(acidente => [
      acidente.rua1,
      acidente.rua2,
      acidente.bairro,
      acidente.cidade,
      acidente.uf,
      acidente.lat,
      acidente.longi,
      acidente.referencia || '',
      new Date(acidente.data_ocorrido).toLocaleDateString('pt-BR', { timeZone: 'UTC' }),
    new Date(acidente.hora).toLocaleTimeString('pt-BR', { timeZone: 'UTC' }),
      acidente.tipo_acidente,
      acidente.pavimento,
      acidente.descricao_sinistro,
      acidente.sugestoes_melhorias,
      acidente.falta_atencao ? 'Sim' : 'Não',
      acidente.orgao_solicitador.nome,
      acidente.primeiro_atendimento.nome,
      acidente.causas.map(causa => causa.tipo).join(', '),
      acidente.infracoes.map(infracao => infracao.tipo).join(', '),
      acidente.orgaos_presentes.map(orgao => orgao.nome).join(', '),
      acidente.sinalizacoes.map(sinalizacao => sinalizacao.tipo).join(', '),

      // veiculos
      acidente.veiculos_retirados,
      acidente.veiculos.map(veiculo => veiculo.tipo).join(', '),   
      acidente.veiculos.map(veiculo => veiculo.marca).join(', '),   
      acidente.veiculos.map(veiculo => veiculo.placa).join(', '),   
      acidente.veiculos.map(veiculo => veiculo.cor).join(', '),     
      acidente.veiculos.map(veiculo => veiculo.modelo).join(', '),  
      acidente.veiculos.map(veiculo => veiculo.transporte).join(', '), 

      // Condutores
      acidente.condutores.map(condutor => condutor.nome).join(', '),     
      acidente.condutores.map(condutor => condutor.cpf).join(', '),      
      acidente.condutores.map(condutor => condutor.idade).join(', '),    
      acidente.condutores.map(condutor => condutor.sexo).join(', '),    
      acidente.condutores.map(condutor => { 
        const veiculoAssociado = acidente.veiculos.find(veiculo => veiculo.id === condutor.veiculo_id)
        return veiculoAssociado ? veiculoAssociado.placa : 'Veículo não encontrado'
      }).join(', '),
      
      // Vítimas
      acidente.vitimas.map(vitima => vitima.nome).join(', '),
      acidente.vitimas.map(vitima => vitima.cpf).join(', '),
      acidente.vitimas.map(vitima => vitima.idade).join(', '),
      acidente.vitimas.map(vitima => vitima.sexo).join(', '),
      acidente.vitimas.map(vitima => vitima.atendimento).join(', '),
      acidente.vitimas.map(vitima => vitima.condicao).join(', '),
      acidente.vitimas.map(vitima => {
        const veiculoAssociado = acidente.veiculos.find(veiculo => veiculo.id === vitima.veiculo_id)
        return veiculoAssociado ? veiculoAssociado.placa : 'Veículo não encontrado'
      }).join(', ')
    ].map(value => `"${value}"`).join(','))

    // Criação do conteúdo CSV
    const csvContent = `data:text/csv;charset=utf-8,${[headers.join(','), ...rows].join('\n')}`
    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
  }

  return (
    <Button
      onClick={exportarParaCSV}
      className='btn btn-icon btn-bg-light btn-active-color-primary me-1'
      style={{width: 80, height: 50}}
    >
      Baixar  <FontAwesomeIcon icon={faDownload} />
    </Button>
  )
}
