import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/system';
import Cookies from 'universal-cookie';
import Avatar from '@mui/material/Avatar';
import imagemAvatar from '../UploadImagem/avatar/avatar.svg';

const cookies = new Cookies();

const AvatarContainer = styled('div')({
  position: 'relative',
  '& .avatar': {
    borderRadius: 0,
  },
  '& .inputFile': {
    position: 'flex',
    top: 0,
    left: 0,
    width: '30%',
    height: '30%',
    opacity: 0,
    cursor: 'pointer',
  },
});

const AvatarComponent = ({ tamanho, borda }) => { 
  const [imagem, setImagem] = useState(null);
  const inputFileRef = useRef(null);

  async function fetchAvatar() {
    try {
      const token = cookies.get('token');
      const id = cookies.get('id');
      const response = await fetch(`${process.env.REACT_APP_NEAT}/agente/${id}/foto`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const blob = await response.blob();
      const imageUrl = URL.createObjectURL(blob);
      setImagem(imageUrl);
    } catch (error) {
      console.error('Erro ao obter os dados da foto:', error);
    }
  }

  useEffect(() => {
    fetchAvatar();
  }, []); 

  const handleImagemChange = (e) => {
    const file = e.target.files[0];
    excluirFotoAtual(); 
    const imageUrl = URL.createObjectURL(file);
    setImagem(imageUrl);
  };

  const excluirFotoAtual = () => {
    setImagem(null);
  };

  return (
    <AvatarContainer>
      <Avatar src={imagem || imagemAvatar} className='avatar' style={{ width: tamanho, height: tamanho, border: borda }} />
      <input ref={inputFileRef} type='file' className='inputFile' onChange={handleImagemChange} />
    </AvatarContainer>
  );
};

export default AvatarComponent;
