import React from 'react';
import { PageTitle } from '../../../_metronic/layout/core';

const PrimeiroBI = () => {
  return (
    <div>
        <PageTitle breadcrumbs={[]}>B.I.</PageTitle>
      <div className='mb-10 text-center'>
          <h1 className='text-dark mb-3'>Primeiro B.I.</h1>
        </div>
      <iframe
        src="https://app.powerbi.com/view?r=eyJrIjoiNDE5YjE3ZTAtNThhNi00MGFhLTk0ZmUtYjRlYTBiYjU3MWFhIiwidCI6ImExZjAxOGVmLWZmZjktNDIyNS1hMWEzLTczMjBkNGUwNmMwYSJ9"
        title="BI Dashboard"
        width="100%"
        height="600px"
        frameBorder="0"
      ></iframe>
    </div>
  );
};

export default PrimeiroBI;
