import React from 'react';
import './styles.css'; // Importe o arquivo de estilos CSS

interface SwitchProps {
  checked?: boolean;
  label?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
}

const CheckboxInput: React.FC<SwitchProps> = ({ checked, onChange, label, name }) => {
  return (
    <div className='row mt-4'>

    <label className=" col-lg-12  switch switch d-flex align-items-center ml-3 mt-30 mx-30">
      {label} 
      <input 
          type="checkbox" 
          checked={checked} 
          onChange={onChange} 
          className="m-20 " 
          style={{margin: '50px'}}
          name={name}
        />
      <span className="slider"></span>
    </label>
    </div>
  );
};

export default CheckboxInput;
