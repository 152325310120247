import React from 'react';
import { PageTitle } from '../../../_metronic/layout/core';
import { PaginaConstrucao } from '../../PaginaConstrucao';

const TerceiroBI = () => {
  const title = "B.Is > TerceiroB.I."
  return (
    <div>
        <PageTitle breadcrumbs={[]}>{title}</PageTitle>
      <div className='mb-10 text-center'>
          <h1 className='text-dark mb-3'>Terceiro B.I.</h1>
        </div>
      {/* <iframe
        src="https://app.powerbi.com/view?r=eyJrIjoiNDE5YjE3ZTAtNThhNi00MGFhLTk0ZmUtYjRlYTBiYjU3MWFhIiwidCI6ImExZjAxOGVmLWZmZjktNDIyNS1hMWEzLTczMjBkNGUwNmMwYSJ9"
        title="BI Dashboard"
        width="100%"
        height="600px"
        frameBorder="0"
      ></iframe> */}
      <PaginaConstrucao mensagem="Página em construção.. Em breve teremos um novo B.I." imagemWidth="30%"/>
    </div>
  );
};

export default TerceiroBI;
