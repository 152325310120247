import React from 'react';
import SegundoBI from '../../../componentes/BI/SegundoBI/SegundoBI';

const SegundoBiPage = () => {
  
  return (
    <div className="card mb-10">
      <SegundoBI />
    </div>
  );
};

export default SegundoBiPage;
