import axios from 'axios'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

export default class HttpService {
  constructor() {
    try {
      this.axios = axios.create({
        baseURL: process.env.REACT_APP_NEAT,
      })


      this.axios.interceptors.request.use((config) => {
        const token = cookies.get('token')
        if (token) {
          config.headers.Authorization = 'Bearer ' + token
        }
        return config
      })
    } catch (error) {
      console.log('errorReq: ', error)
      return error
    }
  }

  async post(url, data) {
    try {
      var msg
      await this.axios
        .post(url, data)
        .then(function (res) {
          msg = res
        })
        .catch(function (error) {
          if (error.response) {
            msg = error.response
          }
        })
      return msg
    } catch (error) {
      console.log(error)
      throw error;
    }
  }

  async get(url) {
    try {
      const response = await this.axios.get(url, {
        headers: {
          Authorization: cookies.get('token') ? `Bearer ${cookies.get('token')}` : '',
        },
      })
      return response.data
    } catch (error) {
      if (error.response) {
        console.error('StatusService: ', error.response.status)
        console.error('Mensagem de erro Service: ', error.response.data)
        return {
          status: error.response.status,
          mensagem: error.response.data,
          data: null,
        }
      } else {
        console.error('Erro ao fazer a requisição Service: ', error.message)
        return {
          status: null,
          mensagem: 'Erro ao fazer a requisição',
          data: null,
        }
      }
    }
  }

  async patch(url, data) {
    try {
      var msg
      await this.axios
        .patch(url, data)
        .then(function (res) {
          msg = res
        })
        .catch(function (error) {
          if (error.response) {
            msg = error.response
          }
        })
      return msg
    } catch (error) {
      console.log(error)
    }
  }

  async put(url, data) {
    try {
      var msg
      await this.axios
        .put(url, data)
        .then(function (res) {
          msg = res
        })
        .catch(function (error) {
          if (error.response) {
            msg = error.response
          }
        })
      return msg
    } catch (error) {
      console.log(error)
    }
  }
  async delete(url) {
    try {
      const response = await this.axios.delete(url, {
        headers: {
          Authorization: cookies.get('token') ? `Bearer ${cookies.get('token')}` : '',
        },
      })
      return response.data
    } catch (error) {
      if (error.response) {
        return {
          status: error.response.status,
          mensagem: error.response.data,
          data: null,
        }
      } else {
        console.error('Erro ao fazer a requisição Delete Service: ', error.message)
        return {
          status: null,
          mensagem: 'Erro ao fazer a requisição Delete',
          data: null,
        }
      }
    }
  }
  
}
