import {useState, useEffect} from 'react'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import {PageTitle} from '../../_metronic/layout/core'
import {obterDadosAcidente} from '../../app/utils/acidenteUtils'
import { DadosNaoEncontrados } from '../DadosNaoEncontrados'

interface Veiculo {
  cor: string
  marca: string
  modelo: string
  placa: string
  tipo: string
  veiculo_retirado: number
}

export default function Veiculos() {
  const [veiculos, setVeiculos] = useState<Veiculo[]>([])

  async function fetchVeiculos() {
    try {
      const {veiculos} = await obterDadosAcidente()
      setVeiculos(veiculos)
    } catch (error) {
      console.log('Erro ao buscar veículos: ', error)
    }
  }

  useEffect(() => {
    fetchVeiculos()
  }, [])

  return (
    <div>
      <PageTitle breadcrumbs={[]}>Detalhamento</PageTitle>
      <strong style={{color: '#a1a5b7'}}>VEÍCULOS</strong>
      <br />
      <br />
      {veiculos.length === 0 ? (
        <DadosNaoEncontrados mensagem='Oops... Nenhum veículo cadastrado' imagemWidth='30%'/>
      ) : (
        <div className='row row-cols-1 row-cols-lg-2 g-9'>
          {veiculos.map((veiculo, index) => (
            <div
              key={index}
              className={`card card-bordered ${veiculos.length === 1 ? 'mx-auto' : ''}`}
            >
              <div className='card-header justify-content-end ribbon ribbon-start ribbon-clip'>
                <div className='card-title'>Veículo {index + 1}</div>
              </div>
              <div className='card-body'>
                <Form>
                  <Row>
                    <Col>
                      <Form.Label>PLACA</Form.Label>
                      <Form.Control
                        type='text'
                        value={veiculo.placa.toUpperCase() || 'NÃO INFORMADO'}
                        disabled
                        className='text-dark'
                        style={{backgroundColor: '#e9ecef'}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>MARCA</Form.Label>
                      <Form.Control
                        type='text'
                        value={veiculo.marca || 'NÃO INFORMADO'}
                        disabled
                        className='text-dark'
                        style={{backgroundColor: '#e9ecef'}}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <Form.Label>COR</Form.Label>
                      <Form.Control
                        type='text'
                        value={veiculo.cor || 'NÃO INFORMADO'}
                        disabled
                        className='text-dark'
                        style={{backgroundColor: '#e9ecef'}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>MODELO</Form.Label>
                      <Form.Control
                        type='text'
                        value={veiculo.modelo || 'NÃO INFORMADO'}
                        disabled
                        className='text-dark'
                        style={{backgroundColor: '#e9ecef'}}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <Form.Label>TIPO</Form.Label>
                      <Form.Control
                        type='text'
                        value={veiculo.tipo || 'NÃO INFORMADO'}
                        disabled
                        className='text-dark'
                        style={{backgroundColor: '#e9ecef'}}
                      />
                    </Col>
                    <Col>
                      <Form.Label>VEICULO RETIRADO?</Form.Label>
                      <Form.Control
                        type='text'
                        value={veiculo.veiculo_retirado === null ? 'NÃO INFORMADO' : (veiculo.veiculo_retirado ? 'SIM' : 'NÃO')}
                        disabled
                        className='text-dark'
                        style={{backgroundColor: '#e9ecef'}}
                      />
                    </Col>
                  </Row>

                </Form>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
