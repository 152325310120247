export const tipoAcidenteList = [
  "ATROPELAMENTO PEDESTRE",
  "QUEDA DE MOTO",
  "QUEDA DE BICICLETA",
  "COLISÃO LATERAL",
  "COLISÃO TRANSVERSAL",
  "COLISÃO FRONTAL",
  "COLISÃO TRASEIRA",
  "CHOQUE",
  "NÃO IDENTIFICADO",
  "COLISÃO OBJETO",
  "OUTROS",
  "ATROPELAMENTO ANIMAL",
  "TOMBAMENTO",
  "ENGAVETAMENTO",
  "ACIDENTE DE CARRO",
  "CAPOTAMENTO",
  "ACIDENTE DE MOTO",
  "COLISÃO OBJETO FIXO",
  "QUEDA NO CANAL",
  "ATROPELAMENTO CICLISTA"
];

export const tipoVeiculoList = [
  "AUTOMÓVEL",
  "BICICLETA",
  "CAÇAMBA",
  "CAMINHAO",
  "CAMINHAO-TRATOR",
  "CAMINHONETE",
  "CICLOMOTOR",
  "MICRO ONIBUS",
  "MOTOCICLETA",
  "MOTONETA",
  "NÃO IDENTIFICADO",
  "ONIBUS",
  "REBOQUE",
  "UTILITARIO",
  "VEICULO DE TRAÇÃO ANIMAL",
  "CARRETA BI-TREM"
];

export const causasList = [
  'INGESTÃO DE ÁLCOOL',
  'DEFEITO MECÂNICO',
  'FALTA DE DISTÂNCIA ENTRE VEÍCULOS',
  'SONO',
  'ULTRAPASSAGEM INDEVIDA',
  'DEFEITO NA VIA',
  'ANIMAIS NA PISTA',
  'CONDIÇÕES CLIMÁTICAS',
  'AVANÇO SEMAFÓRICO',
  'SINALIZAÇAO AUSENTE',
  'SINALIZAÇAO DEFICIENTE',
  'FALTA DE ILUMINAÇAO',
  'AREIA NA VIA',
  'FALTA DE ATENÇÃO',
  'VELOCIDADE INCOMPATÍVEL COM A VIA',
  'DESRESPEITO À SINALIZAÇÃO',
  'DESRESPEITO ÀS NORMAS DE CIRCULAÇÃO',
  'PERSEGUIÇÃO POLICIAL',
];


export default tipoAcidenteList;