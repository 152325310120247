import React, {useState, useEffect} from 'react'
import {obterDadosAcidente} from '../../app/utils/acidenteUtils'
import 'bootstrap/dist/css/bootstrap.min.css' 
import './Galeria.css' 
import {DadosNaoEncontrados} from '../DadosNaoEncontrados'

async function fetchImages(ids: string[], token: string): Promise<string[]> {
  try {
    const imageUrlsPromises = ids.map(async (id: string) => {
     
      const response = await fetch(`https://backneat.sttp.campinagrande.br/foto/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (!response.ok) {
        console.error('Erro ao obter os dados da foto:', response.statusText)
        return null
      }
      const blob = await response.blob()
      const url = URL.createObjectURL(blob)
      return url
    })

    const imageUrls = await Promise.all(imageUrlsPromises)
    return imageUrls.filter((url) => url !== null) as string[]
  } catch (error) {
    console.error('Erro ao obter os dados das fotos:', error)
    return []
  }
}

const Imagens: React.FC = () => {
  const [imageUrls, setImageUrls] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(true);

  const fetchImagesFromBackend = async () => {
    try {
      const token = document.cookie.replace(/(?:(?:^|.*;\s*)token\s*\=\s*([^;]*).*$)|^.*$/, '$1')
      const {fotos} = await obterDadosAcidente()
      const ids = fotos.map((foto: {id: string}) => {
        return foto.id.split('_').pop()?.split('.')[0] || '';
      });

      const imageUrls = await fetchImages(ids, token)
      setImageUrls(imageUrls)
    } catch (error) {
      console.error('Erro ao receber imagem do backend:', error)
    }finally {
      setLoading(false); 
    }
  }

  useEffect(() => {
    fetchImagesFromBackend()
  }, [])

  return (
    <div className='container'>
      <div className='row justify-content-center'>
        <div className='col-md-8'>
          {loading ? ( // Renderiza o spinner de carregamento enquanto as imagens estão sendo carregadas
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100px' }}>
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Carregando...</span>
              </div>
            </div>
          ) : imageUrls.length > 0 ? (
            <div id='carouselExampleControls' className='carousel slide' data-bs-ride='carousel'>
              <div className='carousel-inner'>
                {imageUrls.map((url, index) => (
                  <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
                    <img src={url} className='d-block w-50 mx-auto galeria-imagem' alt={`Imagem ${index + 1}`} />
                  </div>
                ))}
              </div>
              <button
                className='carousel-control-prev'
                type='button'
                data-bs-target='#carouselExampleControls'
                data-bs-slide='prev'
              >
                <span className='carousel-control-prev-icon' aria-hidden='true'></span>
                <span className='visually-hidden'>Anterior</span>
              </button>
              <button
                className='carousel-control-next'
                type='button'
                data-bs-target='#carouselExampleControls'
                data-bs-slide='next'
              >
                <span className='carousel-control-next-icon' aria-hidden='true'></span>
                <span className='visually-hidden'>Próxima</span>
              </button>
            </div>
          ) : (
            <DadosNaoEncontrados mensagem='Oops... Imagens não encontradas' imagemWidth='50%' />
          )}
        </div>
      </div>
    </div>
  );
};


export default Imagens