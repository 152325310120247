/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../../app/modules/auth';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const HeaderUserMenu: FC = () => {
  const { currentUser, logout } = useAuth();
  const [erroHTTP, setErroHTTP] = useState<boolean>(false);
  const [admin, setAdmin] = useState<boolean>(false)

  const deslogar = async () => {
    try {
      const allCookies = cookies.getAll();
      
  
      if (allCookies && Object.keys(allCookies).length > 0) {
        const removeCookiesPromises = Object.keys(allCookies).map(cookieName => {
          cookies.remove(cookieName, { path: '/' });
          cookies.remove(cookieName, { path: '/crafted/account' });
          return Promise.resolve();
        });
  
        await Promise.all(removeCookiesPromises);
        const remainingCookies = cookies.getAll();
        if (Object.keys(remainingCookies).length === 0) {
          window.location.reload();
        }
      } else {
        window.location.reload();
      }
    } catch (error) {
      setErroHTTP(true);
    }
  };

  useEffect(() => {
    
    const allCookies = cookies.getAll();
    const tipo_acesso = allCookies.tipo_acesso
    if (tipo_acesso === 'Admin'){
      setAdmin(true)
      console.log('aa', admin)
    }
    
  }, []);

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className=''>
          <div className='symbol symbol-50px me-5'>
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.first_name} {currentUser?.first_name}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <Link to={'/crafted/pages/profile'} className='menu-link px-5' style={{ textDecoration: 'none' }}>
          Meu Perfil
        </Link>
      </div>
      {admin && <div className='menu-item px-5'>
        <Link to={'/crafted/account/overview'} className='menu-link px-5' style={{ textDecoration: 'none' }}>
          Usuários
        </Link>
      </div>}
      <div className='menu-item px-5'>
        <a onClick={deslogar} className='menu-link px-5' style={{ textDecoration: 'none' }}>
          Sair
        </a>
      </div>
    </div>
  );
}

export { HeaderUserMenu };
