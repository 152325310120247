import React from 'react';
import FormularioEtapas from '../../../componentes/FormularioEtapas';

const Formulario = () => {
  
  return (
    <div className="card mb-10">
      <FormularioEtapas />
    </div>
  );
};

export default Formulario;
