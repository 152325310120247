import React from "react";
import InputMask from 'react-input-mask';

export const MaskedCpf = ({
    placeholder,
    value,
    exibirMensagemValidacao = false,
    mensagemValidacao = '',
    aoAlterarValor
}) => {
    return (
      <div>
        <div>
          <InputMask
            mask="999.999.999-99"
            className='form-control form-control-lg form-control-solid'
            placeholder={placeholder}
            value={value}
            onChange={aoAlterarValor}
          />
        </div>
        {exibirMensagemValidacao && <p className="mensagemValidacao">{mensagemValidacao}</p>}
      </div>
    )
}
