import React, { useEffect, useState } from 'react'
import { PasswordMeterComponent } from '../../_metronic/assets/ts/components'
import Botao from '../../componentes/Botao'
import { InputPublico } from '../modules/auth/components/InputPublic'
import UsuarioService from '../../services/UsuarioService'
import swal from 'sweetalert'
import { FiEye, FiEyeOff } from 'react-icons/fi'

const usuarioService = new UsuarioService()

export function MudarSenha() {
  const [senha, setSenha] = useState('')
  const [confirmaSenha, setConfirmaSenha] = useState('')
  const [token, setToken] = useState('')
  const [mensagem, setMensagem] = useState('')
  const [mensagemClasse, setMensagemClasse] = useState('text-info')
  const [mostrarSenha, setMostrarSenha] = useState(false)

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  const aoSubmeter = async () => {
    if (senha === "") {
      setMensagem('A senha não pode estar vazia.')
      setMensagemClasse('mb-lg-15 alert alert-danger')
      return
    }

    if (senha !== confirmaSenha) {
      setMensagem('As senhas não coincidem.')
      setMensagemClasse('mb-lg-15 alert alert-danger')
      return
    }

    const url = window.location.href
    const token = url.split('=')[1]
    setToken(token)
    try {
      const retorno = await usuarioService.resetandoSenha({
        newPassword: senha,
        token: token,
      })
      if (retorno.status === 200 || retorno.data.status === 201) {
        swal({
          title: 'Sucesso',
          text: 'Senha atualizada com sucesso!',
          icon: 'success',
          buttons: {
            login: {
              text: 'Ir para a página de login',
              value: 'login',
            },
          },
        }).then((value) => {
          if (value === 'login') {
            window.location.href = '/auth/login'
          }
        })
      } else {
        let msg = retorno.data.message
        setMensagem(msg)
        setMensagemClasse('mb-lg-15 alert alert-danger')
        swal({
          title: 'Erro',
          text: msg || 'Oops.. algo deu errado!',
          icon: 'error',
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <div className='mb-10 fv-row' data-kt-password-meter='true'>
        <div className={`mb-10 bg-light-info p-8 rounded ${mensagemClasse}`} style={{ textAlign: 'center' }}>
          <div className='alert-text font-weight-bold'>
            {mensagem ? <strong>{mensagem}</strong> : <strong>Digite sua nova senha.</strong>}
          </div>
        </div>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Senha</label>
          <div className='position-relative mb-3'>
            <InputPublico
              placeholder='Senha'
              type={mostrarSenha ? 'text' : 'password'}
              value={senha}
              aoAlterarValor={(e) => setSenha(e.target.value)}
              className='form-control form-control-lg form-control-solid'
            />
            <span
              className='position-absolute end-0 top-50 translate-middle-y cursor-pointer'
              style={{ padding: '15px' }}
              onClick={() => setMostrarSenha(!mostrarSenha)}
            >
              {mostrarSenha ? <FiEyeOff /> : <FiEye />}
            </span>
          </div>
          <div className='d-flex align-items-center mb-3' data-kt-password-meter-control='highlight'>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
        </div>
        <div className='text-muted'>
          Use 8 ou mais caracteres com uma mistura de letras, números e símbolos.
        </div>
        <div>
          <br />
          <label className='form-label fw-bolder text-dark fs-6'>Confirmar Senha</label>
          <div className='position-relative mb-3'>
            <InputPublico
              placeholder='Senha'
              type={mostrarSenha ? 'text' : 'password'}
              value={confirmaSenha}
              aoAlterarValor={(e) => setConfirmaSenha(e.target.value)}
              className='form-control form-control-lg form-control-solid'
            />
            <span
              className='position-absolute end-0 top-50 translate-middle-y cursor-pointer'
              style={{ padding: '15px' }}
              onClick={() => setMostrarSenha(!mostrarSenha)}
            >
              {mostrarSenha ? <FiEyeOff /> : <FiEye />}
            </span>
          </div>
          <br />
          <Botao
            tipo='button'
            texto='Atualizar'
            className='btn btn-lg btn-primary w-100 mb-5'
            manipularClique={() => aoSubmeter()}
          />
          <a href='/auth/login'>
            <Botao
              tipo='button'
              texto='Cancelar'
              className='btn btn-lg btn-light-primary w-100 mb-5'
            />
          </a>
        </div>
      </div>
    </>
  )
}
