import {useEffect, useState} from 'react'
import swal from 'sweetalert'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {PageTitle} from '../../../../_metronic/layout/core'
import {InputPublico} from './InputPublic'
import {MaskedCpf} from './MaskedCpf'
import {PhoneInput} from '../../../../componentes/Cadastro/PhoneInput'
import UsuarioService from '../../../../services/UsuarioService'
import Botao from '../../../../componentes/Botao'

const usuarioService = new UsuarioService()

export default function CreateAdmin() {
  const [nome, setNome] = useState('')
  const [cpf, setCpf] = useState('')
  const [matricula, setMatricula] = useState('')
  const [email, setEmail] = useState('')
  const [telefone, setTelefone] = useState('')
  const [senha, setSenha] = useState('')

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  const aoSubmeter = async (e) => {
      swal({
        title: 'Usuário sendo cadastrado....',
        allowEscapeKey: false,
        allowOutsideClick: false,
        timer: 10000,
        timerProgressBar: true,
        didOpen: () => {
            swal.showLoading()

        },
        willClose: () => {
            swal.hideLoading();
        }
      });

    const retorno = await usuarioService.cadastroAdmin({
      nome: nome,
      cpf: cpf,
      matricula: matricula,
      senha: senha,
      email: email,
      telefone: telefone,
    })
      if(retorno.status === 201){
        setTimeout(() =>{
            swal({
                text: "Usuário cadastrado com sucesso",
                icon: "success",
                button: "ok"
            })
        }
            ,3000
        )
      }else{
        let msgError = "";
        retorno.data.message.map((error) => {
            msgError += error + "\n"
        })
        setTimeout(() =>{
          swal({
              title: "Oops..",
              text: msgError,
              icon: "error",
              button: "ok"
          })
      }
          ,3000
      )
      }
  }

  return (
    <div id='kt_content_container' class='container-xxl'>
      <div class=' bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
        <PageTitle breadcrumbs={[]}>Criar Novo Administrador</PageTitle>
        <div className='mb-10 text-center'>
          <h1 className='text-dark mb-3'>Cadastre um novo administrador</h1>
        </div>
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          id='kt_login_signup_form'
        >
          {/*Nome*/}
          <label className='class="form-label fw-bolder text-dark fs-6'>Nome Completo</label>

          <InputPublico
            type='text'
            placeholder='Nome Completo'
            aoAlterarValor={(e) => setNome(e.target.value)}
            value={nome}
            className='form-control form-control-lg form-control-solid'
          />

          {/*CPF*/}
          <div className='col-xl-12'>
            <div className='fv-row mb-5'>
              <label className='form-label fw-bolder text-dark fs-6'>CPF</label>

              <MaskedCpf
                placeholder='CPF'
                aoAlterarValor={(e) => setCpf(e.target.value)}
                value={cpf}
              />
            </div>
          </div>

          {/*Matrícula*/}
          <label className='class="form-label fw-bolder text-dark fs-6'>Matrícula</label>
          <InputPublico
            type='text'
            placeholder='Matrícula'
            aoAlterarValor={(e) => setMatricula(e.target.value)}
            value={matricula}
            className='form-control form-control-lg form-control-solid'
          />

          {/*Email*/}
          <label className='class="form-label fw-bolder text-dark fs-6'>Email</label>
          <InputPublico
            type='email'
            placeholder='Email'
            aoAlterarValor={(e) => setEmail(e.target.value)}
            value={email}
            className='form-control form-control-lg form-control-solid'
          />

          {/*Telefone*/}
          <div className='col-xl-12'>
            <div className='fv-row mb-5'>
              <label className='form-label fw-bolder text-dark fs-6'>Telefone</label>
              <PhoneInput
                placeholder='Telefone'
                aoAlterarValor={(e) => setTelefone(e.target.value)}
                value={telefone}
              />
            </div>
          </div>

          {/*Senha*/}
          <div className='mb-10 fv-row' data-kt-password-meter='true'>
            <div className='mb-1'>
              <label className='form-label fw-bolder text-dark fs-6'>Senha</label>
              <div className='position-relative mb-3'>
                <InputPublico
                  placeholder='Senha'
                  type='password'
                  value={senha}
                  aoAlterarValor={(e) => setSenha(e.target.value)}
                  className='form-control form-control-lg form-control-solid'
                />
              </div>
              <div
                className='d-flex align-items-center mb-3'
                data-kt-password-meter-control='highlight'
              >
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
              </div>
            </div>
            <div className='text-muted'>
              Use 8 ou mais caracteres com uma mistura de letras, números e símbolos.
            </div>
          </div>
          <Botao
            tipo='button'
            texto='Cadastrar'
            className='btn btn-lg btn-primary w-100 mb-5'
            manipularClique={() => aoSubmeter()}
          />
        </form>
      </div>
    </div>
  )
}
