import { FC, CSSProperties } from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { checkIsActive, WithChildren } from '../../../helpers';
import { useLayout } from '../../core';

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
  bsTitle?: string;
  style?: CSSProperties; // Adicione a propriedade 'style' ao tipo de props
  backgroundColor?: string; // Adicione a propriedade 'backgroundColor' ao tipo de props
  iconColor?: string; // Adicione a propriedade 'iconColor' ao tipo de props
  titleColor?: string; // Adicione a propriedade 'titleColor' ao tipo de props
};

const AsideMenuItemWithSubMain: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet,
  bsTitle,
  style,
  backgroundColor,
  iconColor,
  titleColor,
}) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);
  const { config } = useLayout();
  const { aside } = config;
  
  const itemStyle: CSSProperties = {
    ...style,
    backgroundColor: backgroundColor || '#1e1e2d',
  };

  const iconStyle: CSSProperties = {
    color: iconColor || '#ffffff',
  };

  const titleStyle: CSSProperties = {
    color: titleColor || '#ffffff',
  };

  return (
    <div
      className={clsx('menu-item py-3', { 'here show': isActive })}
      data-kt-menu-trigger='click'
      data-kt-menu-placement='right-start'
      style={itemStyle} // Passe o estilo para o elemento div principal
    >
      <OverlayTrigger
        placement='right'
        delay={{ show: 250, hide: 400 }}
        overlay={(props) => <Tooltip id='button-tooltip'>{bsTitle}</Tooltip>}
      >
        <span className='menu-link menu-center'>
          {fontIcon && aside.menuIcon === 'font' && (
            <>
              <span className='menu-icon me-0'>
                <i className={clsx('bi', fontIcon, 'fs-2')} style={iconStyle}></i>
              </span>
              <span className='menu-title' style={titleStyle}>{title}</span>
            </>
          )}
        </span>
      </OverlayTrigger>
      <div
        className={clsx('menu-sub menu-sub-dropdown w-225px w-lg-275px px-1 py-4', {
          'menu-active-bg': isActive,
        })}
        style={{backgroundColor: backgroundColor || '#1e1e2d'}}
      >
        {children}
      </div>
    </div>
  );
};

export { AsideMenuItemWithSubMain };
