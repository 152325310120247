import { useEffect, useState } from "react";
import InputFormulario from "../Input/InputFormulario";
import { obterDadosAcidente } from '../../app/utils/acidenteUtils';
import type { Orgao as OrgaoType } from "./types";

export function Orgao() {
    const [orgaoSolicitante, setOrgaoSolicitante] = useState<string>('');
    const [primeiroAtendimento, setPrimeiroAtendimento] = useState<string>('');
    const [orgaosPresentes, setOrgaosPresentes] = useState<OrgaoType[]>([]);

    useEffect(() => {
        async function fetchOrgaos() {
            try {
                const { orgaoSolicitante, primeiroAtendimento, orgaosPresentes } = await obterDadosAcidente();
                setOrgaoSolicitante(orgaoSolicitante || 'Não informado');
                setPrimeiroAtendimento(primeiroAtendimento || 'Não informado');
                setOrgaosPresentes(orgaosPresentes || []);
            } catch (error) {
                console.error('Erro ao obter dados dos órgãos:', error);
            }
        }

        fetchOrgaos();
    }, []);

    return (
        <div>
            <h5 className="text-center">Órgãos</h5>
            <InputFormulario
                value={orgaoSolicitante || 'NÃO INFORMADO'}
                type='string'
                obrigatorio={true}
                placeholder='Órgão a que foi acionado para ocorrência: '
                label='Solicitação da ocorrência: '
                disabled={true}
                style={{ backgroundColor: '#e9ecef' }}
            />
            <InputFormulario
                value={primeiroAtendimento || 'NÃO INFORMADO'}
                type='string'
                obrigatorio={true}
                placeholder='PRIMEIRO ATENDIMENTO: '
                label='Primeiro Atendimento: '
                disabled={true}
                style={{ backgroundColor: '#e9ecef' }}
            />
            <div className='row mt-4'>
                <label className='col-lg-12 col-form-label'>Estiveram na ocorrência:</label>
                <div className='col-lg-9'>
                    <ul
                        className='form-control form-control-solid'
                        style={{
                            backgroundColor: '#e9ecef',
                            paddingLeft: '1rem',
                            listStylePosition: 'inside',
                            margin: 0
                        }}
                    >
                        {orgaosPresentes.map((orgao: OrgaoType, index: number) => (
                            <li key={index} style={{ margin: '0.5rem 0' }}>
                                {orgao.nome || 'NÃO INFORMADO'}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

        </div>
    );
}
