import React from 'react';

export function InputPublico({
  type,
  placeholder,
  value,
  exibirMensagemValidacao = false,
  mensagemValidacao = '',
  aoAlterarValor,
  className,
  style,
  disable,
  id
}) {
  return (
    <div className="col-xl-12">
      <div>
        <input 
          id={id}
          style={style}
          className={className}
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={aoAlterarValor}
          disabled={disable}
        />
      </div>
      {exibirMensagemValidacao && <p className="mensagemValidacao">{mensagemValidacao}</p>}
    </div>
  );
}
