import React, {FC, useEffect, useState} from 'react'
import {KTSVG} from '../../../helpers'
import {allUsuarios} from '../../../../app/utils/usuarioUtils'
import imagemAvatar from '../../../../componentes/UploadImagem/avatar/avatar.svg'
import Cookies from 'universal-cookie'
import UsuarioService from '../../../../services/UsuarioService'
import {Usuario} from './Usuario'
import {ErroHTTP} from '../../../../componentes/Error'
import swal from 'sweetalert'
import Swal from 'sweetalert2';

const cookies = new Cookies()
const usuarioService = new UsuarioService()

const InviteUsers: FC = () => {
  const [users, setUsers] = useState<Usuario[]>([])
  const [imagens, setImagens] = useState<{[id: string]: string}>({})
  const [status, setStatus] = useState<string>()
  const [tipoUsuario, setTipoUsuario] = useState<string>()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [loading, setLoading] = useState(false)

  const token = cookies.get('token')

  async function fetchUsuarios() {
    try {
      const usuarios: Usuario[] = await allUsuarios()
      console.log('uuu', usuarios)
      setUsers(usuarios)
      fetchAvatars(usuarios)
     
    } catch (error) {
      console.error('Erro ao buscar dados do usuário:', error)
      setError('Erro ao buscar dados do usuário')
    }
  }

  async function fetchAvatars(usuarios: Usuario[]) {
    try {
      const promises = usuarios.map(async (user) => {
        try {
          const response = await fetch(
            `https:///backneat.sttp.campinagrande.br/agente/${user.id}/foto`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          if (response.ok) {
            const blob = await response.blob()
            const imageUrl = URL.createObjectURL(blob)
            setImagens((prevImagens) => ({...prevImagens, [user.id]: imageUrl}))
          } else {
            setImagens((prevImagens) => ({...prevImagens, [user.id]: imagemAvatar}))
          }
        } catch (error) {
          setImagens((prevImagens) => ({...prevImagens, [user.id]: imagemAvatar}))
        }
      })
      await Promise.all(promises)
    } catch (error) {
      console.error('Erro ao obter os dados das fotos:', error)
      setError('Erro ao obter os dados das fotos')
    }
  }

  const handleAtualizarUsuario: React.ChangeEventHandler<HTMLSelectElement> = async (event) => {
    setLoading(true)

    const valorSelecionado = event.target.value
    setStatus(valorSelecionado)
    setTipoUsuario(valorSelecionado)

    const usuarioId = event.target.dataset.userId

    if (usuarioId) {
      if (valorSelecionado.toUpperCase() === 'DESATIVADO') {
        try {
          Swal.fire({
            title: 'Desativando usuário...',
            allowOutsideClick: false, 
            didOpen: () => {
              Swal.showLoading();
            },
          });
          await usuarioService.desativarUsuario(usuarioId)
          
            Swal.fire({
              text: 'Usuário desativado com sucesso',
              icon: 'success',
              showConfirmButton: true,
            }).then(() => {
              window.location.assign('/crafted/account/overview')
            })
          setLoading(false)
        } catch (error) {
          console.error('Erro ao desativar o usuário:', error)
          setLoading(false)
          setTimeout(() => {
            Swal.fire({
              title: 'Oops..Algo deu errado.',
              text: 'error',
              icon: 'error',
              showConfirmButton: true,
            });
          }, 3000)
        }
      } else if (valorSelecionado.toUpperCase() === 'ATIVO') {
        try {
          Swal.fire({
            title: 'Ativando usuário...',
            allowOutsideClick: false, 
            didOpen: () => {
              Swal.showLoading();
            },
          }).then(() => {
            window.location.assign('/crafted/account/overview')
          });
          const retorno = await usuarioService.ativarUsuario(usuarioId)
          setLoading(false)
          if (retorno?.status === 200) {
              Swal.fire({
                text: 'Usuário ativado com sucesso',
                icon: 'success',
                showConfirmButton: true,
              }).then(() => {
                window.location.assign('/crafted/account/overview')
              })
          } else {
            //let erro = retorno.data.message;
            setTimeout(() => {
              Swal.fire({
                title: 'Oops..Algo deu errado.',
                text: 'error',
                icon: 'error',
                showConfirmButton: true,
              });
            }, 3000)
          }
        } catch (error) {
          console.error('Erro ao desativar o usuário:', error)
          setLoading(false)
          swal({
            title: 'Oops.. Não foi possivel ativar usuario.',
            icon: 'error',
            buttons: ['ok'],
          }).then(() => {
            window.location.assign('/crafted/account/overview')
          })
          alert('Erro ao desativar o usuário')
        }
      }
      if (valorSelecionado.toUpperCase() === 'ADMIN') {
        try {
          Swal.fire({
            title: 'Atualizando acesso do usuário...',
            allowOutsideClick: false, 
            didOpen: () => {
              Swal.showLoading();
            },
          });
          const retorno = await usuarioService.atualizarUsuario(usuarioId, {
            acesso: 'Admin',
          })
          if (retorno.status === 200) {
            setLoading(false)
            setTimeout(() => {
              Swal.fire({
                text: 'Atualização de acesso feito com sucesso',
                icon: 'success',
                showConfirmButton: true,
              }).then(() => {
                window.location.assign('/crafted/account/overview')
              })
            }, 3000)
          } else {
            let erro = retorno.data.message
            setLoading(false)
            setTimeout(() => {
              Swal.fire({
                title: 'Oops..Algo deu errado.',
                text: erro,
                icon: 'error',
                showConfirmButton: true,
              });
            }, 3000)
          }
        } catch (error) {
          console.error('Erro ao atualizar o usuário: ', error)
          setLoading(false)
        }
      } else if (valorSelecionado.toUpperCase() === 'AGENTE') {
        try {
          const retorno = await usuarioService.atualizarUsuario(usuarioId, {
            acesso: 'Agente',
          })
          Swal.fire({
            title: 'Atualizando acesso do usuário...',
            allowOutsideClick: false, 
            didOpen: () => {
              Swal.showLoading();
            },
          });
          if (retorno.status === 200) {
            setLoading(false)
            setTimeout(() => {
              Swal.fire({
                text: 'Atualização de acesso feito com sucesso',
                icon: 'success',
                showConfirmButton: true,
              }).then(() => {
                window.location.assign('/crafted/account/overview')
              })
            }, 3000)
          } else {
            let erro = retorno.data.message
            setLoading(false)
            setTimeout(() => {
              Swal.fire({
                title: 'Oops..Algo deu errado.',
                text: erro,
                icon: 'error',
                showConfirmButton: true,
              });
            }, 3000)
          }
        } catch (error) {
          console.error('Erro ao atualizar o usuário: ', error)
          setLoading(false)
        }
      }
    } else {
      console.error('ID do usuário não encontrado.')
      setLoading(false)
    }
  } 
  useEffect(() => {
    fetchUsuarios()
  }, [])

  if (error) {
    return <div></div>
  }

  return (
    <div className='modal fade' id='kt_modal_invite_friends' aria-hidden='true'>
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal'>
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
          </div>

          <div className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
            <div className='text-center mb-13'>
              <h1 className='mb-3'>Lista de Usuários</h1>
            </div>

            <div className='mb-10'>
              <div className='fs-6 fw-bold mb-2'>Usuários</div>

              <div className='input-group mb-4'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Pesquisar usuário...'
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>

              <div className='mh-300px scroll-y me-n7 pe-7'>
                {users
                  .filter((user) => user.nome.toLowerCase().includes(searchTerm.toLowerCase()))
                  .sort((a, b) => (a.nome > b.nome ? 1 : -1))
                  .map((user, i) => {
                    const isDesativado = user.status === 'Desativado'
                    const userClass = isDesativado ? 'text-muted' : ''
                    const dotStyle = {
                      backgroundColor: isDesativado ? 'red' : 'green',
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      marginLeft: '10px',
                      display: 'inline-block',
                    }

                    return (
                      <div
                        className={`d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed`}
                        key={i}
                      >
                        <div className='d-flex align-items-center'>
                          <div className='symbol symbol-35px symbol-circle'>
                            {imagens[user.id] ? (
                              <img alt='Pic' src={imagens[user.id]} />
                            ) : (
                              <div
                                className={`symbol symbol-35px symbol-circle bg-light-${user.status} text-${user.status}`}
                              >
                                <span className='symbol-label fw-bold'>{user.nome.charAt(0)}</span>
                              </div>
                            )}
                          </div>

                          <div className='ms-5'>
                            <b
                              className={`fs-5 fw-bolder text-gray-900 text-hover-primary mb-2 fw-normal ${userClass}`}
                            >
                              {user.nome}
                            </b>
                            <span style={dotStyle}></span>
                            <div className='fw-bold'>
                              <b>Status: </b>
                              <span className={``}>{user.status}</span>

                              <b style={{margin: '3px'}}>Matricula: </b>
                              {user.matricula}
                              <b style={{margin: '3px'}}>Acesso: </b>
                              {user.acesso}
                              <br />
                              <b style={{margin: '3px'}}>Email: </b>
                              {user.email}
                            </div>
                          </div>
                        </div>

                        <div className='ms-2 w-100px'>
                          <select
                            onChange={handleAtualizarUsuario}
                            defaultValue=''
                            className='form-select form-select-solid form-select-sm select2-hidden-accessible'
                            data-user-id={user.id}
                            disabled={loading}
                          >
                            <option value='' disabled>
                              {loading ? (
                                <i className='fas fa-spinner fa-spin'></i>
                              ) : (
                                'Selecione...'
                              )}
                            </option>
                            {user.status === 'Ativo' ? (
                              <>
                                <option value='Desativado'>Desativar</option>
                              </>
                            ) : (
                              <>
                                <option value='Ativo'>Ativar</option>
                              </>
                            )}
                            {user.acesso === 'Admin' ? (
                              <>
                                <option value='Agente'>Agente</option>
                              </>
                            ) : (
                              <>
                                <option value='Admin'>Admin</option>
                              </>
                            )}
                          </select>
                        </div>
                      </div>
                    )
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {InviteUsers}
