import React from 'react';
import InputMask from 'react-input-mask';

export const PhoneInput = ({
  placeholder,
  value,
  exibirMensagemValidacao = false,
  mensagemValidacao = '',
  aoAlterarValor
}) => {
  return (
    <div>
      <div>
        <InputMask
          mask="99 99999 9999"
          className='form-control form-control-lg form-control-solid'
          placeholder={placeholder}
          value={value}
          onChange={aoAlterarValor}
          autoComplete="off"
        />
      </div>
      {exibirMensagemValidacao && <p className="mensagemValidacao">{mensagemValidacao}</p>}
    </div>
  )
}