import {localizacao} from '../../componentes/Localizacao/localizacao'
import UsuarioService from '../../services/UsuarioService'
const usuarioService = new UsuarioService()

async function obterDadosAcidente() {
  const idAcidente = localStorage.getItem('acidenteId');
  
  try {
    const retorno = await usuarioService.acidenteID(idAcidente)
    const lat = retorno.lat
    const lng = retorno.longi
    const orgao_solicitador = retorno.orgao_solicitador.nome
    const primeiro_atendimento = retorno.primeiro_atendimento.nome
    const orgaos_presentes = retorno.orgaos_presentes
    const data_ocorrido = retorno.data_ocorrido
    const hora = retorno.hora
    const matricula = retorno.agente_cadastro.matricula
    const fotos = retorno.fotos
    const tipo_acidente = retorno.tipo_acidente
    const causas = retorno.causas
    const pavimento = retorno.pavimento
    const sinalizacoes = retorno.sinalizacoes
    const desrespeito_sinalizacao = retorno.desrespeitos
    const sem_sinalizacao = retorno.falta_sinalizacao
    const reforco_sinalizacao = retorno.reforcos
    const cometimento_infracoes = retorno.infracoes
    const descricao_sinistro = retorno.descricao_sinistro
    const sugestoes_melhorias = retorno.sugestoes_melhorias
    const veiculos = retorno.veiculos
    const condutores = retorno.condutores
    const vitimas = retorno.vitimas

    const estado = localizacao(retorno)
    return {
      orgaoSolicitante: orgao_solicitador,
      primeiroAtendimento: primeiro_atendimento,
      orgaosPresentes: orgaos_presentes,
      lat: lat,
      lng: lng,
      estado: estado,
      data_ocorrido: data_ocorrido,
      hora: hora,
      matricula: matricula,
      fotos: fotos,
      tipo_acidente: tipo_acidente,
      causas: causas,
      pavimento: pavimento,
      sinalizacoes: sinalizacoes,
      desrespeito_sinalizacao: desrespeito_sinalizacao,
      sem_sinalizacao: sem_sinalizacao,
      reforco_sinalizacao: reforco_sinalizacao,
      cometimento_infracoes: cometimento_infracoes,
      descricao_sinistro: descricao_sinistro,
      sugestoes_melhorias: sugestoes_melhorias,
      veiculos: veiculos,
      condutores: condutores,
      vitimas: vitimas,
    }
  } catch (error) {
    console.error('Erro ao obter os dados do acidente Utils:', error)

    throw error
  }
}

export {obterDadosAcidente}
