import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import {Register} from "../pages/Cadastro"
import { AdminPage } from '../pages/AdminPage'
import { Validacao } from '../pages/Validação'
import { FiltroPage } from '../pages/FiltroPage'
import Formulario from '../pages/Formulario'
import { AccountHeader } from '../modules/accounts/AccountHeader'
import PrimeiroBiPage from '../pages/BI/PrimeiroBIPage'
import SegundoBiPage from '../pages/BI/SegundoBIPage'
import TerceiroBiPage from '../pages/BI/TerceiroBiPage'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='formulario' element={<Formulario />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='cadastro' element={<Register/>}/>
        <Route path='criar-admin' element={<AdminPage />}/>
        <Route path='validacao' element={<Validacao />}/>
        <Route path='filtro' element={<FiltroPage />} />
        <Route path='primeiroBI' element={<PrimeiroBiPage />} />
        <Route path='segundoBI' element={<SegundoBiPage />} />
        <Route path='terceiroBI' element={<TerceiroBiPage />} />
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <AccountHeader />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
