import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { MaskedCpf } from './MaskedCpf';
import UsuarioService from '../../../../services/UsuarioService';
import swal from 'sweetalert';

const usuarioService = new UsuarioService();

export function ForgotPassword() {
  const [cpf, setCpf] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const aoSubmeter = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setHasErrors(undefined);

    try {
      const retorno = await usuarioService.esqueciSenha(cpf);
      if (retorno.status === 200) {
        setHasErrors(false);
        setLoading(false);
        swal("Sucesso", "Redefinição de senha enviada. Por favor, verifique seu email.", "success");
      } else {
        setHasErrors(true);
        setErrorMessage(retorno?.data?.message);
        swal({
          title: "Erro",
          text: retorno?.data?.message,
          icon: "error",
        });
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setHasErrors(true);
      setErrorMessage('Ocorreu um erro ao processar sua solicitação.');
      setLoading(false);
    }
  };

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={aoSubmeter}
      >
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>Esqueceu a Senha?</h1>
          <div className='text-gray-400 fw-bold fs-4'>Insira seu CPF para redefinir sua senha.</div>
        </div>

        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              {errorMessage}
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info text-center fs-24'>
              <p>Redefinição de senha enviada. Por favor, verifique seu email.</p>
            </div>
          </div>
        )}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>CPF</label>
          <MaskedCpf
            placeholder='CPF'
            value={cpf}
            aoAlterarValor={(e: React.ChangeEvent<HTMLInputElement>) => setCpf(e.target.value)}
          />
        </div>
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder me-4'
            disabled={loading}
          >
            <span className='indicator-label'>Enviar</span>
            {loading && (
              <span className='indicator-progress'>
                Por favor, espere...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
              disabled={loading}
            >
              Cancelar
            </button>
          </Link>{' '}
        </div>
      </form>
    </>
  );
}
