import React from "react";
import { PageDescription, PageTitle } from "../../_metronic/layout/core";
import { toAbsoluteUrl } from "../../_metronic/helpers";
import {GlassMagnifier} from "react-image-magnifiers";
import {useState} from 'react'

                  
export function Validacao() {
    const [rotation, setRotation] = useState(0);
    //veiculo
    const title = "Validação";
    const subtitle = "Lote 2023/09/TE004 - restam 94";

    const handleImageClick = () => {
        setRotation(rotation + 90);
    };

   // const imageElement = document.getElementById("glass").children[0].children[0];
   // const srcValue = imageElement.getAttribute("src");
    const container = document.getElementById("imageContainer");

     const handleResetClick = () => {
       // imageElement.setAttribute("src", "");
        if (container) {
            container.remove(); 
        }
        
     };

    return (
        <div>
            <PageTitle breadcrumbs={[]} className='text-muted mt-1 fw-semibold fs-7'>
                {title}
            </PageTitle>
            <PageDescription>{subtitle}</PageDescription>
            
            <div className='row row-cols-1 row-cols-lg-2 g-9'>
                <div className='card card-bordered'>
                    <div className='card-body d-flex justify-content-between'>
                        <div id="imageContainer">
                            <div id="glass">
                                <GlassMagnifier
                                    id="magnifierImage"
                                    imageSrc={toAbsoluteUrl("/media/aits/caminhonete.jpg")}
                                    imageAlt="Exemplo"
                                    magnifierSize="30%" 
                                    style={{ transform: `rotate(${rotation}deg)`, cursor: 'pointer' }} // Aplicar a rotação aqui
                                />
                            </div>
                                <div class="col-lg-9">
                                    <button style={{marginTop: `10px`}} type="button" class="btn btn-primary me-2" onClick={handleImageClick}>
                                        <i class="bi bi-arrow-repeat"></i>Girar
                                    </button>
                                    <button type="button" id="excluir-imagem" style={{backgroundColor: 'red', color: 'white', marginTop: `10px`}} class="btn" onClick={handleResetClick}>
                                        <span class="indicator-label"><i class="bi bi-trash3-fill"></i>Excluir</span>
                                    </button>
                                </div>
                        </div>
                    
                    </div>
                </div>
            </div>

                
        </div>
    );
  }
  
