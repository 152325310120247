import {useState, useEffect} from 'react'
import {PasswordMeterComponent} from '../../_metronic/assets/ts/components'
import UploadImagem from '../UploadImagem/index.js';
import UsuarioService from '../../services/UsuarioService'
import { MaskedCpf } from '../../app/modules/auth/components/MaskedCpf'
import { PhoneInput } from './PhoneInput'
import { InputPublico } from '../../app/modules/auth/components/InputPublic'
import {useIntl} from 'react-intl'
import { PageTitle } from '../../_metronic/layout/core'
import Botao from '../Botao'
import swal from 'sweetalert';
import { Link } from 'react-router-dom'
import { Image } from 'react-bootstrap'
import imagemAvatar from '../UploadImagem/avatar/avatar.svg'

const usuarioService = new UsuarioService() 

export function Cadastro() {
  const [nome, setNome] = useState("")
  const [cpf, setCpf] = useState("")
  const [matricula, setMatricula] = useState("")
  const [email, setEmail] = useState("")
  const [telefone, setTelefone] = useState("")
  const [senha, setSenha] = useState("")
  const [imagem, setImagem] = useState(null)

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  const aoSubmeter = async(e) => {
      swal({
        title: 'Usuário sendo cadastrado....',
        allowEscapeKey: false,
        allowOutsideClick: false,
        timer: 10000,
        timerProgressBar: true,
        didOpen: () => {
            swal.showLoading()
        
        },
        willClose: () => {
            swal.hideLoading();
        }
      });
      if(imagem?.arquivo){
        data.append("file", imagem.arquivo);
      }
      const data = {
        "nome": nome,
        "cpf": cpf,
        "matricula": matricula,
        "senha": senha,
        "email": email,
        "telefone": telefone,
      }
      const retorno = await usuarioService.cadastro({
          "nome": nome,
          "cpf": cpf,
          "matricula": matricula,
          "senha": senha,
          "email": email,
          "telefone": telefone,
      })
      if(retorno.status === 201){
        setTimeout(() =>{
            swal({
                text: "Usuário cadastrado com sucesso",
                icon: "success",
                button: "ok"
            })
        }
            ,3000
        )
      }else{
        let erro = "";
        retorno.data.message.map((error) => {
          erro += error + '\n';
        })
        setTimeout(() =>{
          swal({
              title: "Oops..",
              text: erro,
              icon: "error",
              button: "ok"
          })
      }
          ,3000
      )
      }
  }
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>Usuário</PageTitle>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
      >
        
        <div className='mb-10 text-center'>
          <h1 className='text-dark mb-3'>Cadastre um novo usuário</h1>
        </div>
        <div className='text-gray-500 text-center fw-semibold fs-6'>
          Já tem conta? {' '}
          <Link to='/auth/login' className='link-primary'>
            Faça Login
          </Link>
        </div>
        <br/><br/>
        <div className="logoContainer desktop">
            <Image 
                src={imagemAvatar}
                alt='logotipo'
                layout="fill"
                className="logo"
            />
        </div>
        <UploadImagem 
            imagemPreview = {imagem?.preview || imagemAvatar.src}
            imagemPreviewClassName = 'avatar avatarPreview'
            setImagem = {setImagem}
        />
        {/*Nome*/}
        <label className='class="form-label fw-bolder text-dark fs-6'>Nome Completo</label>
        <InputPublico 
          type='text'
          placeholder='Nome Completo'
          aoAlterarValor={(e) => setNome(e.target.value)}
          value={nome}
          className='form-control form-control-lg form-control-solid'
        />

        {/*CPF*/}
        <div className='col-xl-12'>
          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>CPF</label>
            <MaskedCpf 
              placeholder='CPF'
              aoAlterarValor={(e) => setCpf(e.target.value)}
              value={cpf}
            />
          </div>
        </div>

        {/*Matrícula*/}
        <label className='class="form-label fw-bolder text-dark fs-6'>Matrícula</label>
        <InputPublico 
          type='text'
          placeholder='Matrícula'
          aoAlterarValor={(e) => setMatricula(e.target.value)}
          value={matricula}
          className='form-control form-control-lg form-control-solid'
        />

        {/*Email*/}
        <label className='class="form-label fw-bolder text-dark fs-6'>Email</label>
        <InputPublico 
          type='email'
          placeholder='Email'
          aoAlterarValor={(e) => setEmail(e.target.value)}
          value={email}
          className='form-control form-control-lg form-control-solid'
        />

        {/*Telefone*/}
        <div className='col-xl-12'>
          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>Telefone</label>
            <PhoneInput 
              placeholder='Telefone'
              aoAlterarValor={(e) => setTelefone(e.target.value)}
              value={telefone}
              
            />
          </div>
          
        </div>

        {/*Senha*/}
        <div className='mb-10 fv-row' data-kt-password-meter='true'>
          <div className='mb-1'>
            <label className='form-label fw-bolder text-dark fs-6'>Senha</label>
            <div className='position-relative mb-3'>
            <InputPublico 
              placeholder='Senha'
              type="password"
              value={senha}
              aoAlterarValor={e => setSenha(e.target.value)}
              className='form-control form-control-lg form-control-solid'

            />
              
            </div>
            <div
              className='d-flex align-items-center mb-3'
              data-kt-password-meter-control='highlight'
            >
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
            </div>
          </div>
          <div className='text-muted'>
          Use 8 ou mais caracteres com uma mistura de letras, números e símbolos.
          </div>
        </div>
        <Botao 
          tipo='button'
          texto='Cadastrar'
          className='btn btn-lg btn-primary w-100 mb-5'
          manipularClique={() => aoSubmeter()}
        
        />
      </form>
    </>
  )
}
