import React, { useState } from 'react';
import Multiselect from 'multiselect-react-dropdown';

export function MultiSelect({ opcoes, onSelect, label, placeholder, onRemove, onChange }) {
  

  return (
    <React.Fragment>
      <div className='row mt-4'>
        <label className='col-lg-12 col-form-label'>{label}</label>
        <form method='post'>
          <Multiselect
            style={{ border: 'none !important' }}
            className='col-lg-9 form-control form-control-solid'
            isObject={false}
            placeholder={placeholder}
            options={opcoes}
            onRemove={onRemove}
            onSelect={onSelect}
            onChange={onChange}
          />
        </form>
      </div>
    </React.Fragment>
  );
}
