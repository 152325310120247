import React from 'react'
import Veiculos from '../Veiculos'
import Condutores from '../Condutores'
import Vitimas from '../Vitimas'

//consumir API - https://www.devmedia.com.br/consumindo-uma-api-com-react-js-e-axios/42900

export default function LineTabsInfracao() {
  return (
    <div>
      <h5 className='text-center'>VEÍCULOS - CONDUTORES - VÍTIMAS </h5>
      <ul className='nav nav-tabs nav-line-tabs mb-5 fs-6'>
        <li className='nav-item'>
          <a className='nav-link active' data-bs-toggle='tab' href='#resumo'>
            Veículos
          </a>
        </li>
        <li className='nav-item'>
          <a className='nav-link' data-bs-toggle='tab' href='#geral'>
            Condutores
          </a>
        </li>
        <li className='nav-item'>
          <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_2'>
            Vítimas
          </a>
        </li>
      </ul>
      <div className='tab-content' id='myTabContent'>
        <div className='tab-pane fade active show' id='resumo' role='tabpanel'>
          <Veiculos />
        </div>
        <div className='tab-pane fade' id='geral' role='tabpanel'>
          <Condutores />
        </div>
        <div className='tab-pane fade' id='kt_tab_pane_2' role='tabpanel'>
          <Vitimas />
        </div>
      </div>
    </div>
  )
}
