import React from 'react';
import PrimeiroBI from '../../../componentes/BI/PrimeiroBI';

const PrimeiroBiPage = () => {
  
  return (
    <div className="card mb-10">
      <PrimeiroBI />
    </div>
  );
};

export default PrimeiroBiPage;
