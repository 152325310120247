import React from "react";
import { Cadastro } from "../../componentes/Cadastro/Index";
import { Link } from "react-router-dom";
                  
export function Register() {
    return (
    <div className=" bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
        
        <Cadastro />
    </div>
    )
}