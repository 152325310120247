import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import Formulario from '../Formulario'
import { FiltroPage } from '../FiltroPage'

const DashboardPage = () => {
 useEffect(() => {
    //We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])

  return (
    <div>
      <h1></h1>
    </div>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <div id='dassh'>
    <FiltroPage />
    {/* <Validacao/>  */}
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'RAT'})}</PageTitle>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle> */}
      <DashboardPage />
    </div>
  )
}

export {DashboardWrapper}
