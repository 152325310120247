import React from 'react';
import imagemNaoEncontrada from './naoEncontrada.png';

interface Props {
  mensagem?: string;
  fontSize?: string;
  imagemWidth?: string;
}

export function DadosNaoEncontrados({ mensagem, fontSize = '1.5em', imagemWidth = '40%' }: Props) {
  const styles = {
    container: {
      cursor: 'default',
      userSelect: 'none',
      pointerEvents: 'none',
      textAlign: 'center'
    } as React.CSSProperties,
    heading: {
      fontSize,
      marginTop: '1em',
    },
    image: {
      width: imagemWidth,
    }
  };

  return (
    <div style={styles.container}>
      <h5 style={styles.heading}>{mensagem}</h5>
      <img src={imagemNaoEncontrada} alt="Dados não encontrados" style={styles.image} />
    </div>
  );
}
