// utils/dateUtils.ts

export function dataFormat(data: any, endOfDay = false): string {
  if (!data) return '';
  const dataObj = new Date(data);
  if (isNaN(dataObj.getTime())) return '';
  if (endOfDay) {
    dataObj.setUTCHours(23, 59, 59, 999);
  } else {
    dataObj.setUTCHours(0, 0, 0, 0);
  }
  const dataFormatada = dataObj.toISOString();
  return dataFormatada;
}

export const formatarData = (data: string | Date) => {
  const dataUTC = typeof data === 'string' ? new Date(data) : data;
  return dataUTC.toLocaleDateString('pt-BR', {
    timeZone: 'UTC',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};


export const formatarHora = (hora: string | Date ) => {
  const horaUTC = typeof hora === 'string' ? new Date(hora) : hora;
  return horaUTC.toLocaleTimeString('pt-BR', {
    timeZone: 'UTC',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
};