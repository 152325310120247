/* eslint-disable react/jsx-no-target-blank */
import {AsideMenuItem} from './AsideMenuItem'
import {AsideMenuItemWithSubMain} from './AsideMenuItemWithSubMain'

export function AsideMenuMain() {
  return (
    <>
      <AsideMenuItem
        to='/filtro'
        title='Estatística'
        fontIcon='bi bi-file-earmark-bar-graph-fill'
      />
      {/* <AsideMenuItem to='/formulario' title='Formulário' fontIcon='bi bi-file-earmark-text-fill' /> */}

      <AsideMenuItemWithSubMain
        to='/'
        title=' B.Is.'
        fontIcon='bi bi-bar-chart-fill'
        iconColor='#ffffff'
        titleColor='#ffffff'
      >
        <AsideMenuItem to='/primeiroBI' title='Primeiro B.I.' fontIcon="'bi bi-bar-chart-fill" />
        <AsideMenuItem to='/segundoBI' title='Segundo B.I.' fontIcon='bi bi-bar-chart' />
        <AsideMenuItem to='/terceiroBI' title='Terceiro B.I.' fontIcon='bi bi-bar-chart-fill' />
      </AsideMenuItemWithSubMain>

      {/* <AsideMenuItem
        to=''/novasenha
        title='Esqueci a Senha'
        fontIcon='bi bi-file-earmark-bar-graph-fill'
      /> */}
    </>
  )
}
