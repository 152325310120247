export const localizacao = (retorno: any) => {
    const { rua1, rua2, bairro, cidade, uf, referencia } = retorno;
  
    const estado = {
      logradouro: rua1 || '',
      logradouro2: rua2 || '',
      cruzamento: rua2 !== null,
      bairro: bairro || '',
      cidade: cidade || '',
      uf: uf || '',
      pontoDeReferencia: referencia || '',
    };
  
    return estado;
  };
  