import React from 'react';
import imagem from './9.png';

interface Props {
  mensagem?: string;
  msgError?: string; // Adicionando a propriedade msgError à interface Props
  fontSize?: string;
  imagemWidth?: string;
}

export function PaginaConstrucao({ mensagem, fontSize = '1.5em', imagemWidth = '40%', msgError }: Props) {
  return (
    <div className="text-center">
      <h5 className='mt-4' style={{ fontSize }}>{mensagem}</h5>
      <label className='col-lg-12' style={{color: 'red'}}>{msgError}</label>
      <img src={imagem} alt="Erro de HTTP" style={{ width: imagemWidth }} />
    </div>
  );
}
