import React from 'react';

interface BotaoProps {
    tipo?: 'button' | 'submit' | 'reset';
    texto: string;
    desabilitado?: boolean;
    manipularClique?: () => void;
    onPress?: () => void; // Se você estiver usando React Native, mantenha isso. Para React web, você pode remover isso.
    className?: string;
}

const Botao: React.FC<BotaoProps> = ({
    tipo = 'button',
    texto,
    desabilitado = false,
    manipularClique,
    onPress,
    className
}) => {
    return(
        <button
            type={tipo}
            className={className}
            disabled={desabilitado}
            onClick={manipularClique}
        >
            {texto}
        </button>
    )
}

export default Botao;
