import React, {useEffect, useRef, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {allRatsUsusario, obterDadosUsuario} from '../../utils/usuarioUtils'
import InputFormulario from '../../../componentes/Input/InputFormulario'
import {styled} from '@mui/system'
import Cookies from 'universal-cookie'
import AvatarComponent from '../../../componentes/AvatarComponent'

const cookies = new Cookies()

const LogoContainer = styled('div')({
  '& .inputFile': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '30%',
    height: '30%',
    opacity: 0,
    cursor: 'pointer',
  },
})

const AccountHeader: React.FC = () => {
  const [nome, setNome] = useState<string>('')
  const [cpf, setCpf] = useState<string>('')
  const [sexo, setSexo] = useState<string>('')
  const [matricula, setMatricula] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [telefone, setTelefone] = useState<string>('')
  const [status, setStatus] = useState<string>('')
  const [tipoUsuario, setTipoUsuario] = useState<string>('')
  const [totalAcidentes, setTotalAcidentes] = useState<string>('')
  const [imagem, setImagem] = useState<string | null>(null)
  const inputFileRef = useRef<HTMLInputElement>(null)

  const location = useLocation()

  async function fetchUsuarios() {
    try {
      const dadosUsuario = await obterDadosUsuario()
      if (dadosUsuario) {
        const {nome, cpf, sexo, matricula, email, telefone, status, tipoUsuario} = dadosUsuario

        setNome(nome || 'Não informado')
        setCpf(cpf || 'Não informado')
        setSexo(sexo || 'Não informado')
        setMatricula(matricula || 'Não informado')
        setEmail(email || 'Não informado')
        setTelefone(telefone || 'Não informado')
        setStatus(status || 'Não informado')
        setTipoUsuario(tipoUsuario || 'Não informado')
        
      } else {
        console.error('Dados do usuário não encontrados')
      }
    } catch (error) {
      console.error('Erro ao buscar dados do usuário:', error)
    }
  }

  async function fetchAvatar() {
    try {
      const avatarUrl = async () => {
        const token = cookies.get('token')
        const id = cookies.get('id')
        const response = await fetch(`${process.env.REACT_APP_NEAT}/agente/${id}/foto`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const blob = await response.blob()
        return URL.createObjectURL(blob) 
      }

      const imageUrl = await avatarUrl()
      setImagem(imageUrl)
    } catch (error) {
      console.error('Erro ao obter os dados das fotos:', error)
    }
  }

  async function totalRatRealizado() {
    try {
      const totalRatPorUsuario = await allRatsUsusario()
      setTotalAcidentes(totalRatPorUsuario.length)
    } catch (error) {
      console.error('Erro totalRatRealizado:', error)
    }
  }

  useEffect(() => {
    fetchUsuarios()
    fetchAvatar()
    totalRatRealizado()
  }, [])

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='card mb-5 mb-xl-10'>
          <div className='card-body pt-9 pb-0'>
            <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
              <div className='me-7 mb-4' style={{width: '40%'}}>
                <div
                  className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative '
                  style={{width: '40%'}}
                >
                  <LogoContainer>
                    <AvatarComponent tamanho='100%' borda='1px solid #d2d2d2' />
                    <input ref={inputFileRef} type='file' className='inputFile' />
                  </LogoContainer>
                  <div
                    className={`position-absolute translate-middle bottom-0 start-100 mb-6 bg-${
                      status.toUpperCase() === 'DESATIVADO' ? 'danger' : 'success'
                    } rounded-circle border border-4 border-white h-20px w-20px`}
                  ></div>
                </div>
              </div>

              <div className='flex-grow-1'>
                <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                  <div className='d-flex flex-column'>
                    <div className='d-flex align-items-center mb-2'>
                      <a
                        href='#'
                        className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'
                        style={{textDecoration: 'none'}}
                      >
                        {nome}
                      </a>
                      <a href='#'>
                        <KTSVG
                          path='/media/icons/duotune/general/gen026.svg'
                          className='svg-icon-1 svg-icon-primary'
                        />
                      </a>
                    </div>

                    <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                      <label className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'>
                        <KTSVG
                          path='/media/icons/duotune/communication/com006.svg'
                          className='svg-icon-4 me-1'
                        />
                        {tipoUsuario}
                      </label>
                      <label className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'>
                        <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                          <div
                            className={`position-absolute translate-middle start-100 mb-6 bg-${
                              status.toUpperCase() === 'DESATIVADO' ? 'danger' : 'success'
                            } rounded-circle border border-4 border-white h-20px w-20px`}
                            
                          ></div>
                        </div>
                        <label className='status' style={{paddingLeft: '30%'}}>
                          {status}
                        </label>
                      </label>
                      <label className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'>
                        <KTSVG
                          path='/media/icons/duotune/communication/com013.svg'
                          className='svg-icon-4 me-1'
                        />
                        {matricula}
                      </label>
                    </div>
                  </div>
                </div>

                <div className='d-flex flex-wrap flex-stack '>
                  <div className='d-flex flex-column flex-grow-1 pe-8'>
                    <div className='d-flex flex-wrap'>
                      <div className='d-flex flex-wrap flex-stack'>
                        <div className='d-flex flex-column flex-grow-1 pe-8'>
                          <div className='d-flex flex-wrap'>
                            <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                              <div className='d-flex align-items-center'>
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr066.svg'
                                  className='svg-icon-3 svg-icon-success me-2'
                                />
                                <div className='fs-2 fw-bolder'>{totalAcidentes}</div>
                              </div>

                              <div className='fw-bold fs-6 text-gray-400'>
                                Total de RATS Cadastrados!
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            {tipoUsuario.toUpperCase() === 'ADMIN' && (
              <li className='nav-item'>
                <Link
                  className={`nav-link text-active-primary me-6 ${
                    (location.pathname === '/crafted/account/overview' && 'active') || ''
                  }`}
                  to='/crafted/account/overview'
                >
                  Adicionar Usuário
                </Link>
              </li>
            )}
            <li className='nav-item'>
              <Link
                className={`nav-link text-active-primary me-6 ${
                  (location.pathname === '/crafted/account/settings' && 'active') || ''
                }`}
                to='/crafted/account/settings'
              >
                Detalhes do perfil
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export {AccountHeader}
