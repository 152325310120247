import UsuarioService from '../../services/UsuarioService'
import Cookies from 'universal-cookie'

const cookies = new Cookies()
const usuarioService = new UsuarioService()

const id = cookies.get('id');
const mat = cookies.get('matricula')

async function allRatsUsusario(){
  try {
    const allRatsPorUsuario = await usuarioService.allRatsCadastradosUsuario(id)
    return allRatsPorUsuario;
  } catch (error) {
    console.log('error avatar: ', error);
    return null;
  }
}
async function allUsuarios(){
  try {
    const usuarios = await usuarioService.allUsuarios()
    return usuarios;
  } catch (error) {
    console.log('error avatar: ', error);
    return null;
  }
}
async function obterDadosUsuario() {
  
  try {
    const retorno = await usuarioService.usuario(mat)
    const nome = retorno.nome
    const cpf = retorno.cpf
    const sexo = retorno.sexo
    const matricula = retorno.matricula
    const email = retorno.email
    const telefone = retorno.telefone
    const status = retorno.status
    const tipoUsuario = retorno.acesso
    const foto = await obterFotoUsuario()
    return {
      nome,
      cpf,
      sexo,
      matricula,
      email,
      telefone,
      status,
      tipoUsuario,
      foto,
    }
  } catch (error) {
    console.error('Erro ao obter os dados do usuario Utils:', error)
  }
}
async function obterFotoUsuario() {
  try {
    const avatarRetorno = await usuarioService.avatar(id);
    return avatarRetorno;
  } catch (error) {
    console.log('error avatar: ', error);
    return null;
  }
}

export {obterDadosUsuario, allRatsUsusario, allUsuarios}
